import React from 'react';
import { LineContext } from 'components/context';
import UsersMembersPage from 'pages/users/members';
import usePages from 'src/customHooks/pages';

export default function Home(props) {
  const { message, token } = usePages();
  if (token) {
    return (
      <LineContext.Provider value={message}>
        <UsersMembersPage {...props} {...message}></UsersMembersPage>
      </LineContext.Provider>
    );
  }
  return null;
}
